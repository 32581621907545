import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Table, Input, Modal } from "antd";
import { get } from "~/request";
import { getSearch } from "../../../../utils";

export default function ArticleList({
  open,
  onCancel,
  currentPaper,
  setCurrentPaper,
  languageType,
}) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [tableList, setTableList] = useState([]); // 列表
  const [selectedRowKeys, setSelectedRowKeys] = useState([1]); // 选中行
  const [tableColumns, setTableColumns] = useState();
  const [gradeItems, setGradeItems] = useState();
  const getPaperList = (id) => {
    get({
      url: `/paper/list?cid=${id}`, // cid学科
      onSuccess: (data) => {
        data.sort((a, b) => {
          return a.createdAt - b.createdAt;
        });
        data.forEach((item, index) => {
          item["key"] = index + 1;
          item["vol"] = index + 1;
          item["remark"] = !item.remark ? null : item.remark;
        });
        setTableList([...data]);
      },
    });
  };
  useEffect(() => {
    getPaperList(params.id);
  }, [open]);

  const getNum = (text) => {
    switch (text) {
      case 0:
        return "--";
      case 1:
        return gradeItems[0].label;
      case 2:
        return gradeItems[1].label;
      case 3:
        return gradeItems[2].label;
      default:
        break;
    }
  };
  useEffect(() => {
    if (tableList.length != 0) {
      setTableColumns([
        {
          title: languageType == "cn" ? "论文标题" : "Title",
          dataIndex: "title",
          key: "title",
          width: 180,
          render: (text, record) => (
            <a
              style={{ color: "black" }}
              onClick={() => {
                setSelectedRowKeys([record.key]);
                setCurrentPaper(null);
                navigate({
                  pathname: location.pathname,
                  search: `pid=${record.id}&lang=${
                    getSearch(location.search).lang
                  }`,
                });
                onCancel();
              }}
            >
              {record.vol}. {text}
            </a>
          ),
        },
        {
          title: languageType == "cn" ? "问题" : "Problem",
          dataIndex: "s1",
          key: "s1",
          width: 80,
          render: (text) => getNum(text),
        },
        {
          title: languageType == "cn" ? "理论方法" : "Theoretical Method",
          dataIndex: "s2",
          key: "s2",
          width: 80,
          render: (text) => getNum(text),
        },
        {
          title: languageType == "cn" ? "实践方法" : "Practical Method",
          dataIndex: "s3",
          key: "s3",
          width: 80,
          render: (text) => getNum(text),
        },
        {
          title: languageType == "cn" ? "效果" : "Effects",
          dataIndex: "s4",
          key: "s4",
          width: 80,
          render: (text) => getNum(text),
        },
        {
          title: languageType == "cn" ? "结论" : "Conclusion",
          dataIndex: "s5",
          key: "s5",
          width: 80,
          render: (text) => getNum(text),
        },
        {
          title:
            languageType == "cn"
              ? "您的主观评价"
              : "Your subjective assessment",
          dataIndex: "remark",
          key: "remark",
          width: 200,
          render: (text) => <span>{text}</span>,
        },
      ]);
    }
    setGradeItems([
      {
        value: "1",
        label: languageType == "cn" ? "非常合理" : "Very Reasonable",
      },
      {
        value: "2",
        label: languageType == "cn" ? "合理" : "Reasonable",
      },
      {
        value: "3",
        label: languageType == "cn" ? "不合理" : "Unreasonable",
      },
    ]);
  }, [tableList, languageType]);

  useEffect(() => {
    if (currentPaper) {
      setSelectedRowKeys([currentPaper.key]);
    }
  }, [currentPaper]);

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      wrapClassName={styles.modalStyle}
    >
      <Table
        bordered
        size="large"
        columns={tableColumns}
        dataSource={tableList}
        pagination={{ hideOnSinglePage: true, pageSize: 50 }}
        scroll={{
          y: 600,
        }}
        rowSelection={{
          type: "radio",
          columnWidth: 0,
          defaultSelectedRowKeys: [1],
          selectedRowKeys: selectedRowKeys,
        }}
      />
    </Modal>
  );
}
