import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { Collapse, Typography, Input, Select, Spin, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  LegendComponent,
  PolarComponent,
} from "echarts/components";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { get, post } from "~/request";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { getSearch } from "~/utils";
import axios from "axios";
import ReactMarkdown from 'react-markdown'
moment.locale("zh-cn");
const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;
echarts.use([
  TitleComponent,
  LegendComponent,
  RadarChart,
  CanvasRenderer,
  PolarComponent,
]);

const handleKey = (key) => {
  let tempKey = key.charAt(0).toUpperCase() + key.slice(1);
  return key.includes("_") ? tempKey.replace("_", " ") : tempKey;
};

export default function Analyze({ paperData, getInfo, languageType }) {
  const location = useLocation();
  const params = useParams();
  const [paper, setPaper] = useState();
  const [otherItems, setOtherItems] = useState([]); // 深度相关论文
  const [similarPapers, setSimilarPapers] = useState(); // 按年限划分深度相关论文
  const [checkedPaper, setCheckedPaper] = useState(); // 选中的文章
  const [checkedPaperTitle, setCheckedPaperTitle] = useState(); // 选中文章的title
  const [relevantPapersItems, setRelevantPapersItems] = useState([]); // 相关论文
  // Relevant papers
  const [currentArticleItems, setCurrentArticleItems] = useState([]);
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(false);

  const scoreKeys = {
    1: "s1",
    2: "s2",
    3: "s3",
    4: "s4",
    5: "s5",
  };

  const evaluateItems = [
    {
      key: "1",
      label: "非常合理",
    },
    {
      key: "2",
      label: "合理",
    },
    {
      key: "3",
      label: "不合理",
    },
  ];
  const getNum = (text) => {
    switch (text) {
      case 0:
        return " ";
      case 1:
        return evaluateItems[0].label;
      case 2:
        return evaluateItems[1].label;
      case 3:
        return evaluateItems[2].label;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log(paperData);
    if (paperData) {
      // 获取分析报告
      get({
        url: "/paper/analysis",
        params: {
          v: 3,
          doi: paperData.doi,
          cid: paperData.cid,
        },
        onSuccess: (data) => {
          setInfo(JSON.parse(data));
        },
      });
      setPaper(paperData);
      // 获取相关论文
      get({
        url: "/paper/related",
        params: {
          doi: paperData.doi,
        },
        ignoreError: true,
        onSuccess: (data) => {
          let tempArr = [];
          console.log("--相关论文--", JSON.parse(data));
          JSON.parse(data).forEach((item, index) => {
            tempArr.push({
              key: index,
              label: item.title,
              children: (
                <Typography>
                  {item.abstract && (
                    <>
                      <Title level={4}>
                        {languageType == "cn" ? "摘要" : "Abstract"}:
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {item.abstract}
                      </Paragraph>
                    </>
                  )}
                  <Paragraph style={{ fontSize: 16 }}>
                    <Text strong style={{ fontSize: 18 }}>
                      DOI:
                    </Text>
                    &nbsp;{item.doi}
                  </Paragraph>
                  <Title level={4}>
                    {languageType == "cn" ? "作者" : "Authors"}
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {item.author.map((it, idx) => {
                      return (
                        it.full_name + (idx + 1 != item.author.length && ", ")
                      );
                    })}
                  </Paragraph>
                  <div className={styles.details}>
                    {item.pub_date && (
                      <div>
                        <Title level={4}>
                          {languageType == "cn"
                            ? "发表日期"
                            : "Publication date"}
                        </Title>
                        <Paragraph style={{ fontSize: 16 }}>
                          {moment(item.pub_date).format("YYYY-MM-DD")}
                        </Paragraph>
                      </div>
                    )}
                    <div>
                      <Title level={4}>
                        {languageType == "cn" ? "期刊" : "Journal"}
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {item.journal}
                      </Paragraph>
                    </div>
                    <div>
                      <Title level={4}>
                        {languageType == "cn" ? "出版社" : "Publisher"}
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {item.publisher}
                      </Paragraph>
                    </div>
                  </div>
                </Typography>
              ),
            });
          });
          setRelevantPapersItems(tempArr);
        },
      });
    } else {
      // 设置loading
      setLoading(true);
      setPaper(null);
      setInfo(null);
    }
  }, [paperData]);

  const postScore = (param) => {
    post({
      url: "/paper/score",
      data: {
        pid: param.id,
        s1: Number(param.s1),
        s2: Number(param.s2),
        s3: Number(param.s3),
        s4: Number(param.s4),
        s5: Number(param.s5),
        remark: param.remark,
      },
      onSuccess: (data) => {
        console.log(data);
        setPaper({
          ...paperData,
          s1: Number(param.s1),
          s2: Number(param.s2),
          s3: Number(param.s3),
          s4: Number(param.s4),
          s5: Number(param.s5),
          remark: param.remark,
        });
      },
    });
  };

  const gradeClick = (value, text) => {
    console.log("值:", value, "类别:", text, "当前文章id:", paper);
    paper[text] = value;
    postScore(paper);
  };

  const changeRemark = (e, text) => {
    paper[text] = e.target.value;
    postScore(paper);
  };

  const checkedPaperItem = (info) => {
    setCheckedPaper([
      {
        key: "1",
        label: (
          <strong>
            {languageType == "cn" ? "选中论文" : "Selected paper"}
          </strong>
        ),
        children: (
          <Typography>
            {info.similarity && (
              <Title level={4}>相似度：{info.similarity.toFixed(2)}</Title>
            )}
            <Title level={4}>{languageType == "cn" ? "标题" : "Title"}</Title>
            <Paragraph style={{ fontSize: 16 }}>
              {info.metadata.title}
            </Paragraph>
            <Title level={4}>
              {languageType == "cn" ? "摘要" : "Abstract"}
            </Title>
            <Paragraph style={{ fontSize: 16 }}>
              {info.metadata.abstract}
            </Paragraph>
            <Title level={4}>DOI</Title>
            <Paragraph style={{ fontSize: 16 }}>{info.metadata.doi}</Paragraph>
            {info.metadata.author.length != 0 && (
              <>
                <Title level={4}>
                  {languageType == "cn" ? "作者" : "Authors"}
                </Title>
                <Paragraph style={{ fontSize: 16 }}>
                  {info.metadata.author.map((it, idx) => {
                    return (
                      it.full_name +
                      (idx + 1 != info.metadata.author.length ? ", " : "")
                    );
                  })}
                </Paragraph>
              </>
            )}
            <div className={styles.details}>
              {info.metadata.pub_date && (
                <div>
                  <Title level={4}>
                    {languageType == "cn" ? "发表日期" : "Publication date"}
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {moment(info.metadata.pub_date).format("YYYY-MM-DD")}
                  </Paragraph>
                </div>
              )}
              <div>
                <Title level={4}>
                  {languageType == "cn" ? "期刊" : "Journal"}
                </Title>
                <Paragraph style={{ fontSize: 16 }}>
                  {info.metadata.journal}
                </Paragraph>
              </div>
              <div>
                <Title level={4}>
                  {languageType == "cn" ? "出版社" : "Publisher"}
                </Title>
                <Paragraph style={{ fontSize: 16 }}>
                  {info.metadata.publisher}
                </Paragraph>
              </div>
            </div>
          </Typography>
        ),
      },
    ]);
  };

  // info数据展示
  useEffect(() => {
    if (info && paper) {
      getInfo(info);
      console.log("--深度相关论文--", info, languageType);
      let reports =
        languageType == "cn"
          ? info.report.split("##")
          : info.report_ENG.split("##");
      setCurrentArticleItems([
        {
          key: "1",
          label: (
            <strong>
              {languageType == "cn" ? "文章信息" : "Basic Article Information"}
            </strong>
          ),
          children: (
            <Typography>
              <Title level={4}>{languageType == "cn" ? "标题" : "Title"}</Title>
              <Paragraph style={{ fontSize: 16 }}>
                {info.metadata.title}
              </Paragraph>
              <Title level={4}>
                {languageType == "cn" ? "摘要" : "Abstract"}
              </Title>
              <Paragraph style={{ fontSize: 16 }}>
                {info.metadata.abstract}
              </Paragraph>
              <div className={styles.details}>
                {info.metadata.author && (
                  <div>
                    <Title level={4}>
                      {languageType == "cn" ? "作者" : "Authors"}
                    </Title>
                    <Paragraph style={{ fontSize: 16 }}>
                      {info.metadata.author.map((it, idx) => {
                        return (
                          it.full_name +
                          (idx + 1 != info.metadata.author.length ? ", " : "")
                        );
                      })}
                    </Paragraph>
                  </div>
                )}
                <div>
                  <Title level={4}>DOI</Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {info.metadata.doi}
                  </Paragraph>
                </div>
                {info.summary.subject && (
                  <div style={{ minWidth: "max-content" }}>
                    <Title level={4}>
                      {languageType == "cn" ? "学科" : "Subject"}
                    </Title>
                    <Paragraph style={{ fontSize: 16 }}>
                      {info.summary.subject}
                    </Paragraph>
                  </div>
                )}
                {info.metadata.pub_date && (
                  <div>
                    <Title level={4}>
                      {languageType == "cn" ? "发表日期" : "Publication date"}
                    </Title>
                    <Paragraph style={{ fontSize: 16 }}>
                      {moment(info.metadata.pub_date).format("YYYY-MM-DD")}
                    </Paragraph>
                  </div>
                )}
                <div>
                  <Title level={4}>
                    {languageType == "cn" ? "期刊" : "Journal"}
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {info.metadata.journal}
                  </Paragraph>
                </div>
                <div>
                  <Title level={4}>
                    {languageType == "cn" ? "出版社" : "Publisher"}
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {info.metadata.publisher}
                  </Paragraph>
                </div>
                <div>
                  <Title level={4}>
                    {languageType == "cn" ? "论文被引数" : "Cited-by counts"}
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {info.metadata.citations ? info.metadata.citations : "N/A"}
                  </Paragraph>
                </div>
                <div>
                  <Title level={4}>
                    {languageType == "cn"
                      ? "期刊影响因子"
                      : "Journal Impact Factor"}
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {info.metadata.impact_factor
                      ? info.metadata.impact_factor
                      : "N/A"}
                  </Paragraph>
                </div>
                <div>
                  <Title level={4}>
                    {languageType == "cn" ? "期刊JCR分区" : "JCR Quartile"}
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {info.metadata.jcr_quantile
                      ? info.metadata.jcr_quantile
                      : "N/A"}
                  </Paragraph>
                </div>
              </div>
            </Typography>
          ),
        },
        {
          key: "2",
          label: (
            <strong>
              {languageType == "cn"
                ? "五维分析及原创性报告"
                : "5-Dimensional Analysis and Novelty Report"}
            </strong>
          ),
          children: (
            <Typography>
              <div style={{ height: 300 }}>
                <div
                  id="quintuple"
                  style={{ width: "100%", height: "100%" }}
                ></div>
              </div>
              {reports.map((item, index) => {
                item = index != 0 ? "##" + item : item;
                return (
                  <React.Fragment key={index}>
                    <ReactMarkdown>{item}</ReactMarkdown>
                    {index > 0 && index < reports.length - 1 && (
                      <div className={styles.isOk}>
                        {languageType == "cn"
                          ? "您觉得该部分评价是否合理？"
                          : "How will you rate this part of evaluation?"}
                        <Select
                          size="large"
                          value={getNum(paper[scoreKeys[index]])}
                          onChange={(value) =>
                            gradeClick(value, scoreKeys[index])
                          }
                          options={[
                            {
                              value: "1",
                              label:
                                languageType == "cn"
                                  ? "非常合理"
                                  : "Very Reasonable",
                            },
                            {
                              value: "2",
                              label:
                                languageType == "cn" ? "合理" : "Reasonable",
                            },
                            {
                              value: "3",
                              label:
                                languageType == "cn"
                                  ? "不合理"
                                  : "Unreasonable",
                            },
                          ]}
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
              <div className={styles.evaluate}>
                <p>
                  {languageType == "cn"
                    ? "您对本报告的主观评价"
                    : "Your comments to this analysis report."}
                </p>
                <TextArea
                  key={Math.random()}
                  rows={4}
                  defaultValue={paper.remark}
                  showCount={true}
                  onBlur={(e) => changeRemark(e, "remark")}
                />
              </div>
            </Typography>
          ),
        },
        {
          key: "3",
          label: (
            <strong>
              {languageType == "cn"
                ? "改进建议及多维度总结"
                : "Improvement Suggestions and 5-dimensional summary."}
            </strong>
          ),
          children: (
            <>
              <Paragraph style={{ fontSize: 16 }}>
                <Title level={3}>
                  {languageType == "cn"
                    ? "改进建议"
                    : "Improvement Suggestions"}
                </Title>
                {info.rating.improve_suggest}
              </Paragraph>
              <Title level={3}>
                {languageType == "cn" ? "多维度总结" : "5-dimensional summary"}
              </Title>
              {Object.keys(info.summary).map((key) => {
                return (
                  <div key={key}>
                    {key != "summary" && (
                      <Paragraph key={key} style={{ fontSize: 16 }}>
                        <Text strong style={{ fontSize: 18 }}>
                          {handleKey(key)}
                        </Text>
                        :
                        {info.summary[key] == ""
                          ? info.rating[key].reason
                          : info.summary[key]}
                      </Paragraph>
                    )}
                  </div>
                );
              })}
              {info.summary.summary != "" && (
                <Paragraph style={{ fontSize: 16 }}>
                  <Text strong style={{ fontSize: 18 }}>
                    Summary
                  </Text>
                  :{info.summary.summary}
                </Paragraph>
              )}
            </>
          ),
        },
      ]);
      checkedPaperItem(info);
      setCheckedPaperTitle(info.metadata.title);
      let tempArr = [];
      let tempStr = {
        inner: [],
        outer: [],
        others: [],
      };
      let currentYear = moment(info.metadata.pub_date).format("YYYY");
      info.similar_papers.sort((a, b) => {
        return b.similarity - a.similarity;
      });
      // 深度相关论文
      info.similar_papers.forEach((item, index) => {
        let itemYear = moment(item.metadata.pub_date).format("YYYY");
        if (itemYear >= currentYear - 2 && itemYear < currentYear) {
          if (tempStr.inner.length < 4) {
            tempStr.inner.push(item);
          } else {
            tempStr.others.push(item);
          }
        } else if (itemYear >= currentYear - 10 && itemYear < currentYear - 2) {
          if (tempStr.outer.length < 8) {
            tempStr.outer.push(item);
          } else {
            tempStr.others.push(item);
          }
        } else {
          tempStr.others.push(item);
        }
      });
      tempStr && setSimilarPapers({ ...tempStr });
      // 其他深度相关论文
      tempStr.others.length != 0 &&
        tempStr.others.forEach((item, index) => {
          if (index < 8) {
            tempArr.push({
              key: index,
              label: (
                <>
                  <strong>
                    {languageType == "cn" ? "相似度" : "Similarity"}:&nbsp;
                    {item.similarity.toFixed(2)}
                  </strong>
                  &nbsp;{item.metadata.title}
                </>
              ),
              children: (
                <Typography>
                  <Title level={4}>
                    {languageType == "cn" ? "摘要" : "Abstract"}:
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {item.metadata.abstract}
                  </Paragraph>
                  <Paragraph style={{ fontSize: 16 }}>
                    <Text strong style={{ fontSize: 18 }}>
                      DOI:
                    </Text>
                    &nbsp;{item.metadata.doi}
                  </Paragraph>
                  <div className={styles.details}>
                    {item.metadata.pub_date && (
                      <div>
                        <Title level={4}>
                          {languageType == "cn"
                            ? "发表日期"
                            : "Publication date"}
                        </Title>
                        <Paragraph style={{ fontSize: 16 }}>
                          {moment(item.metadata.pub_date).format("YYYY-MM-DD")}
                        </Paragraph>
                      </div>
                    )}
                    <div>
                      <Title level={4}>
                        {languageType == "cn" ? "期刊" : "Journal"}
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {item.metadata.journal}
                      </Paragraph>
                    </div>
                    <div>
                      <Title level={4}>
                        {languageType == "cn" ? "出版社" : "Publisher"}
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {item.metadata.publisher}
                      </Paragraph>
                    </div>
                  </div>
                  {/* <Title level={4}>多维度总结</Title>
                {Object.keys(item.summary).map((key, index) => {
                  return (
                    <Paragraph key={index} style={{ fontSize: 16 }}>
                      <Text strong style={{ fontSize: 18 }}>
                        {handleKey(key)}
                      </Text>
                      :{item.summary[key]}
                    </Paragraph>
                  );
                })} */}
                </Typography>
              ),
            });
          }
        });
      tempArr.length != 0 && setOtherItems([...tempArr]);
      // 关闭loading
      setLoading(false);
    }
  }, [info, paper, languageType]);

  useEffect(() => {
    // 五维分析图
    if (info) {
      var chartDom = document.getElementById("quintuple");
      if (chartDom) {
        var myChart = echarts.init(chartDom);
        let option = {
          angleAxis: {
            startAngle: 0,
          },
          radiusAxis: {
            type: "value",
            min: 0,
            max: 100,
            z: 1,
          },
          polar: {
            radius: 100,
          },
          radar: {
            shape: "circle",
            radius: 100,
            color: "black",
            indicator: [
              {
                name: languageType == "cn" ? "问题" : "Problem",
                max: 100,
              },
              {
                name: languageType == "cn" ? "理论方法" : "Theoretical Method",
                max: 100,
              },
              {
                name: languageType == "cn" ? "实践方法" : "Practical Method",
                max: 100,
              },
              {
                name: languageType == "cn" ? "效果" : "Effects",
                max: 100,
              },
              {
                name: languageType == "cn" ? "结论" : "Conclusion",
                max: 100,
              },
            ],
            axisName: {
              color: "black",
            },
            nameGap: 20,
          },
          series: [
            {
              name: "Five-dimensional analysis",
              type: "radar",
              data: [
                {
                  value: [
                    info.rating.question.score,
                    info.rating.theoretical_method.score,
                    info.rating.practical_method.score,
                    info.rating.effects.score,
                    info.rating.conclusion.score,
                  ],
                },
              ],
              label: {
                show: true,
              },
              areaStyle: {
                color: "#0068c9",
                opacity: 0.2,
              },
            },
          ],
        };

        myChart.setOption(option);

        // reason
        // myChart.on("click", function (params) {
        //   console.log(option.radar.indicator[params.event.target.__dimIdx].name);
        // });
      }
    }
  }, [currentArticleItems, languageType]);

  // 获取选中文章信息
  const selectedArticle = (article) => {
    console.log("--article--", article);
    setCheckedPaperTitle(article.metadata.title);
    checkedPaperItem(article);
  };

  return (
    <div className={styles.analyze}>
      {loading ? (
        <Spin tip="加载中..." size="large">
          <span></span>
        </Spin>
      ) : (
        <>
          {currentArticleItems.length != 0 && (
            <>
              <h2>
                {languageType == "cn" ? "五维创新评价" : "Competitive Analysis"}
              </h2>
              <Collapse
                items={currentArticleItems}
                defaultActiveKey={["1", "2", "3"]}
                ghost
                expandIconPosition="end"
                size="large"
              />
            </>
          )}
          {/* 深度相关论文 */}
          <h2>
            {languageType == "cn" ? "深度相关论文" : "Deeply related papers"}
            &nbsp;
            <Tooltip
              title={
                languageType == "cn"
                  ? "基于论文idea的高维向量相似度匹配"
                  : "High dimensional vector similarity matching based on idea"
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </h2>
          <div className={styles.interaction}>
            <div className={styles.ring}>
              <div className={styles.innerCircle}>
                <div
                  className={styles.center}
                  onClick={() => selectedArticle(info)}
                  style={{
                    backgroundColor:
                      checkedPaperTitle == info?.metadata.title && "magenta",
                    color: checkedPaperTitle == info?.metadata.title && "white",
                  }}
                >
                  {languageType == "cn" ? "本文" : "This article"}
                  <br />
                  {moment(info?.metadata.pub_date).format("YYYY")}
                </div>
                {similarPapers &&
                  similarPapers.inner.length != 0 &&
                  similarPapers?.inner.map((it, idx) => {
                    return (
                      <div
                        className={styles.inner}
                        key={idx}
                        onClick={() => selectedArticle(it)}
                        style={{
                          backgroundColor:
                            checkedPaperTitle == it.metadata.title && "magenta",
                          color:
                            checkedPaperTitle == it.metadata.title && "white",
                        }}
                      >
                        <span>
                          {it.similarity.toFixed(2)},
                          <br />
                          {moment(it.metadata.pub_date).format("YYYY")}
                        </span>
                      </div>
                    );
                  })}
              </div>
              {similarPapers &&
                similarPapers.outer.length != 0 &&
                similarPapers?.outer.map((it, idx) => {
                  return (
                    <div
                      className={styles.outer}
                      key={idx}
                      onClick={() => selectedArticle(it)}
                      style={{
                        backgroundColor:
                          checkedPaperTitle == it.metadata.title && "magenta",
                        color:
                          checkedPaperTitle == it.metadata.title && "white",
                      }}
                    >
                      <span>
                        {it.similarity.toFixed(2)},
                        <br />
                        {moment(it.metadata.pub_date).format("YYYY")}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
          <Collapse
            items={info && checkedPaper}
            defaultActiveKey={["1"]}
            ghost
            expandIconPosition="end"
            accordion={true}
            size="large"
          />
          {/* 其他深度相关论文 */}
          {otherItems.length != 0 && (
            <>
              <h2>
                {languageType == "cn"
                  ? "其余深度相关论文"
                  : "The rest of the depth related papers"}
                <span style={{ color: "grey" }}>
                  （{languageType == "cn" ? "部分" : "section"}）
                </span>
                <Tooltip
                  title={
                    languageType == "cn"
                      ? "基于论文idea的高维向量相似度匹配"
                      : "High dimensional vector similarity matching based on idea"
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </h2>
              <Collapse
                className={styles.bot}
                items={info && otherItems}
                ghost
                expandIconPosition="end"
                accordion={true}
                size="large"
              />
            </>
          )}
          {/* 相关论文 */}
          {relevantPapersItems.length != 0 && (
            <>
              <h2>
                {languageType == "cn" ? "相关论文" : "Relevant papers"}
                <span style={{ color: "grey" }}>
                  （{languageType == "cn" ? "部分" : "section"}）
                </span>
                <Tooltip
                  title={
                    languageType == "cn"
                      ? "传统关键词匹配技术结果（仅供对比）"
                      : "Results of traditional keyword matching techniques (for comparison only)"
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </h2>
              <Collapse
                className={styles.bot}
                items={relevantPapersItems}
                ghost
                expandIconPosition="end"
                accordion={true}
                size="large"
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
