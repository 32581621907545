import React from "react";
import styles from "./head.scss";
import { TranslationOutlined } from "@ant-design/icons";

export default function Head({changeLanguage}) {
  return (
    <div className={styles.head}>
      <div>
        <h2>CUHK-Shenzhen test program</h2>
        <TranslationOutlined
          style={{ fontSize: 17 }}
          onClick={() => {
            changeLanguage();
          }}
        />
      </div>
    </div>
  );
}
