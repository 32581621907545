import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import Subject from "./pages/subject/subject";

export default function Router({ languageType }) {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home languageType={languageType} />}></Route>
        <Route path="/home" element={<Home languageType={languageType} />}></Route>
        <Route path="/evaluate/:id" element={<Subject languageType={languageType} />}></Route>
      </Routes>
    </React.Fragment>
  );
}
