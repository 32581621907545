import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Tooltip, message } from "antd";
import { get, post } from "~/request";
import PdfPreview from "./modules/pdfPreview/pdfPreview";
import Analyze from "./modules/analyze/analyze";
import ArticleList from "./modules/articleList/articleList";
import moment from "moment";
import { getSearch } from "~/utils";

export default function Subject({ languageType }) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // 评分弹窗
  const [currentPaper, setCurrentPaper] = useState();
  const [tableList, setTableList] = useState([]); // 列表
  const [info, setInfo] = useState(); // 当前文章数据

  const getPaperList = (id) => {
    get({
      url: `/paper/list?cid=${id}`, // cid学科
      onSuccess: (data) => {
        data.sort((a, b) => {
          return a.createdAt - b.createdAt;
        });
        data.forEach((item, index) => {
          item["key"] = index + 1;
          item["vol"] = index + 1;
          item["remark"] = !item.remark ? null : item.remark;
        });
        setTableList([...data]);
        if (!getSearch(location.search)) {
          setCurrentPaper(null);
          navigate({
            pathname: location.pathname,
            search: `pid=${data[0].id}`,
          });
        }
      },
    });
  };

  // 获取文章列表
  useEffect(() => {
    getPaperList(params.id);
  }, [params.id]);

  // 获取当前文章
  useEffect(() => {
    if (tableList.length != 0) {
      const pid = new URLSearchParams(location.search).get("pid");
      let current = null;
      tableList.forEach((item) => {
        if (item.id == pid) {
          current = item;
        }
      });
      if (current) {
        setCurrentPaper(current);
      } else {
        setCurrentPaper(tableList[0]);
        navigate({
          pathname: location.pathname,
          search: `pid=${tableList[0].id}&lang=${
            getSearch(location.search).lang
          }`,
        });
      }
    }
  }, [tableList, location.search, location.key]);

  useEffect(() => {
    if (currentPaper) {
      const pid = currentPaper.id;
      const viewAt = moment().valueOf();
      const gapSecs = 10;

      const recordView = () => {
        console.log("view", pid);
        post({
          url: "/paper/view",
          data: {
            pid,
            viewAt,
            time: gapSecs,
          },
        });
      };
      console.log("start", pid);
      let handler = setInterval(recordView, gapSecs * 1000);

      const onVisibilityChange = () => {
        console.log(document.visibilityState, pid);
        if (document.visibilityState === "hidden") {
          clearInterval(handler);
        } else if (document.visibilityState === "visible") {
          handler = setInterval(recordView, gapSecs * 1000);
        }
      };
      document.addEventListener("visibilitychange", onVisibilityChange);
      return () => {
        console.log("end", pid);
        document.removeEventListener("visibilitychange", onVisibilityChange);
        clearInterval(handler);
      };
    }
  }, [currentPaper]);

  useEffect(() => {
    const showTime = (e) => {
      const { shiftKey, key } = e;
      const pid = new URLSearchParams(location.search).get("pid");
      console.log(shiftKey, key, pid, location.search);
      if (shiftKey && (key === "s" || key === "S")) {
        get({
          url: "/paper/view",
          onSuccess: (data) => {
            data.forEach((d) => {
              let { time } = d;
              if (d.pid == pid && time > 0) {
                let msg = "您已经预览该文章";
                if (time >= 3600) {
                  msg += Math.floor(time / 3600) + "小时";
                  time %= 3600;
                }
                if (time >= 60) {
                  msg += Math.floor(time / 60) + "分";
                  time %= 60;
                }
                if (time > 0) {
                  msg += time + "秒";
                }
                message.info(msg);
              }
            });
          },
        });
      }
    };
    document.addEventListener("keypress", showTime);
    return () => {
      document.removeEventListener("keypress", showTime);
    };
  }, [location.search]);

  const previous = () => {
    setCurrentPaper(null);
    navigate({
      pathname: location.pathname,
      search: `pid=${tableList[currentPaper.key - 2].id}&lang=${
        getSearch(location.search).lang
      }`,
    });
  };

  const next = () => {
    setCurrentPaper(null);
    navigate({
      pathname: location.pathname,
      search: `pid=${tableList[currentPaper.key].id}&lang=${
        getSearch(location.search).lang
      }`,
    });
  };

  return (
    <div className={styles.subject}>
      <ArticleList
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        currentPaper={currentPaper}
        setCurrentPaper={setCurrentPaper}
        languageType={languageType}
      />
      <div className={styles.title}>
        <Button
          type="primary"
          disabled={currentPaper?.key == 1}
          onClick={previous}
          size="large"
        >
          {languageType == "cn" ? "前一篇" : "Previous"}
        </Button>
        <Tooltip
          placement="bottom"
          title={currentPaper?.vol + ". " + currentPaper?.title}
        >
          <h2>
            {currentPaper?.vol}. {currentPaper?.title}
            <br />
          </h2>
        </Tooltip>
        <Button
          type="primary"
          disabled={currentPaper?.key == tableList?.length}
          onClick={next}
          size="large"
        >
          {languageType == "cn" ? "后一篇" : "Next"}
        </Button>
      </div>
      <div className={styles.tit}>
        <div>
          <span style={{ width: languageType == "en" ? 83 : 45 }}>
            {languageType == "cn" ? "原文" : "Full text"}
          </span>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
            }}
            size="large"
          >
            {languageType == "cn" ? "文章列表" : "Article List"}
          </Button>
        </div>
        <div className={styles.titDetail}>
          <span>
            {info?.metadata.author &&
              info.metadata.author.map((it, idx) => {
                return (
                  it.full_name +
                  (idx + 1 != info.metadata.author.length ? ", " : "")
                );
              })}
          </span>
          <span>
            {info?.metadata.journal};{" "}
            {moment(info?.metadata.pub_date).format("YYYY-MM-DD")}
          </span>
        </div>
        <span style={{ minWidth: languageType == "cn" ? 135 : 170 }}>
          {languageType == "cn" ? "独立品牌分析" : "Analysis Results"}
        </span>
      </div>
      <div className={styles.articleAnalysis}>
        {currentPaper && (
          <PdfPreview prfUrl={currentPaper.link} paperData={currentPaper} />
        )}
        <Analyze
          paperData={currentPaper}
          getInfo={setInfo}
          languageType={languageType}
        />
      </div>
    </div>
  );
}
