import React, { useEffect, useState } from "react";
import styles from "./app.scss";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Head from "./components/head/head";
import { get, post } from "./request";
import Router from "./router";
import { getSearch } from "./utils";

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [currentTab, setCurrentTab] = useState("主页");
  const [languageType, setLanguageType] = useState("cn");
  const [tabs, setTabs] = useState([
    {
      id: 0,
      name: "主页",
      en: "Home",
    },
  ]);
  const changeVol = (newVol) => {
    setCurrentTab(newVol);
  };

  useEffect(() => {
    if (!user) {
      post({
        url: "/login/anonymous",
        data: {},
        onSuccess: (res) => {
          console.log("--", res);
          setUser(res.user);
        },
      });
    }
  }, [user]);

  useEffect(() => {
    get({
      url: "/paper/categories",
      onSuccess: (data) => {
        const { categories } = data;
        let temp = [tabs[0], ...categories];
        setTabs(temp);
      },
    });
    get({
      url: "/user/info",
      onSuccess: (data) => {
        setUser(data);
      },
      onFail: () => {
        setUser(null);
      },
    });
  }, []);

  // 刷新后根据pathname选中tab
  useEffect(() => {
    if (location.pathname.includes("evaluate")) {
      let id = location.pathname.substring(10);
      tabs.forEach((it) => {
        if (it.id == id) {
          setCurrentTab(it.name);
        }
      });
    } else {
      setCurrentTab(tabs[0].name);
    }
  });

  useEffect(() => {
    if (languageType) {
      if (location.search.indexOf("lang=") != -1) {
        navigate({
          pathname: location.pathname,
          search: location.search.replace(
            `lang=${languageType == "en" ? "cn" : "en"}`,
            `lang=${languageType}`
          ),
        });
      } else {
        navigate({
          pathname: location.pathname,
          search: `lang=${languageType}`,
        });
      }
    }
  }, [languageType]);

  // 根据url确定语言
  useEffect(() => {
    console.log(getSearch(location.search));
    if (
      getSearch(location.search) &&
      getSearch(location.search).lang &&
      getSearch(location.search).lang != languageType
    ) {
      setLanguageType(getSearch(location.search).lang);
    }
  }, []);

  return (
    <React.Fragment>
      <Head
        user={user}
        changeVol={changeVol}
        changeLanguage={() => {
          setLanguageType(languageType == "en" ? "cn" : "en");
        }}
      />
      <div className={styles.main}>
        <div
          className={styles.tabs}
          style={{ fontSize: languageType == "en" && 22 }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              tab={tab}
              active={tab.name === currentTab}
              onClick={() => {
                setCurrentTab(tab.name);
              }}
              changeVol={changeVol}
              languageType={languageType}
            />
          ))}
        </div>
        <Router languageType={languageType} />
      </div>
    </React.Fragment>
  );
}

function Tab({ tab, active, onClick, languageType }) {
  return (
    <Link
      to={tab.id == 0 ? `/home?lang=${languageType}` : `/evaluate/${tab.id}?lang=${languageType}`}
      className={`${styles.tab} ${active ? styles.active : ""}`}
      onClick={onClick}
    >
      <span>{languageType == "en" ? tab.en : tab.name}</span>
    </Link>
  );
}
