import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { Document, Page, pdfjs } from "react-pdf";
import { InputNumber, Divider } from "antd";
import { MenuOutlined, UndoOutlined } from "@ant-design/icons";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const initialPageWith = (window.innerWidth * 0.95 * 2) / 3 - 300;

// 展示pdf
export default function PdfPreview({ prfUrl }) {
  const [pageNumber, setPageNumber] = useState(1); // 页码
  const [pageWidth, setPageWidth] = useState(initialPageWith); // 预览pdf的宽度
  const [numPages, setNumPages] = useState(1); // 总页数
  const [ratio, setRatio] = useState(100); // 缩放比例
  const [ratioInput, setRatioInput] = useState(100); // 缩放比例input

  // 分页
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // 缩放
  const onChangeScale = (value) => {
    console.log("onChangeScale", value);
    if (value <= 1) {
      setRatioInput(1);
    } else {
      setRatioInput(value);
    }
  };

  const onPressEnterRatio = (e) => {
    console.log("onPressEnterRatio", e.target.value);
    setRatio(ratioInput);
    setPageWidth(initialPageWith * ratioInput * 0.01);
  };

  const pageZoomOut = () => {
    let value = Math.max(50, ratio - 50);
    setRatio(value);
    setPageWidth(initialPageWith * value * 0.01);
  };
  const pageZoomIn = () => {
    let value = ratio + 50;
    setRatio(value);
    setPageWidth(initialPageWith * value * 0.01);
  };

  // 重置
  const reDefault = () => {
    setRatio(100);
    setRatioInput(100);
    setPageWidth(initialPageWith);
    setPageNumber(1);
  };

  return (
    <div className={styles.pdfView}>
      <div className={styles.container}>
        <Document
          file={prfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={"加载中..."}
        >
          {new Array(numPages).fill("").map((item, index) => {
            return (
              <Page
                key={index}
                pageNumber={index + 1}
                width={pageWidth}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                loading={index == 0 && "加载中..."}
              />
            );
          })}
        </Document>
      </div>
      <div className={styles.pageTool}>
        <MenuOutlined />
        <InputNumber
          defaultValue={100}
          min={0}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace("%", "")}
          onChange={onChangeScale}
          onPressEnter={onPressEnterRatio}
          addonBefore={<span onClick={pageZoomOut}>-</span>}
          addonAfter={<span onClick={pageZoomIn}>+</span>}
          controls={false}
          size="small"
          value={ratio}
        />
        <Divider type="vertical" />
        <UndoOutlined onClick={reDefault} />
      </div>
    </div>
  );
}
